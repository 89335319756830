import React, { useState } from "react";

export type JourneyContextType = {
  locale: string;
  setLocale: React.Dispatch<React.SetStateAction<string>> | null;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>> | null;
  dateOfBirth: string;
  setDateOfBirth: React.Dispatch<React.SetStateAction<string>> | null;
  sex: string;
  setSex: React.Dispatch<React.SetStateAction<string>> | null;
  postcode: string;
  setPostcode: React.Dispatch<React.SetStateAction<string>> | null;
  insuranceCover: string;
  setInsuranceCover: React.Dispatch<React.SetStateAction<string>> | null;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>> | null;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>> | null;
  minCapital: number;
  setMinCapital: React.Dispatch<React.SetStateAction<number>> | null;
  maxCapital: number;
  setMaxCapital: React.Dispatch<React.SetStateAction<number>> | null;
  basicFrom: number;
  setBasicFrom: React.Dispatch<React.SetStateAction<number>> | null;
  premiumFrom: number;
  setPremiumFrom: React.Dispatch<React.SetStateAction<number>> | null;
  leadId: string;
  setLeadId: React.Dispatch<React.SetStateAction<string>> | null;
};

export const JourneyContext = React.createContext<JourneyContextType>({
  name: "",
  setName: null,
  dateOfBirth: "",
  setDateOfBirth: null,
  sex: "",
  setSex: null,
  postcode: "",
  setPostcode: null,
  insuranceCover: "",
  setInsuranceCover: null,
  phoneNumber: "",
  setPhoneNumber: null,
  email: "",
  setEmail: null,
  minCapital: 0,
  setMinCapital: null,
  maxCapital: 0,
  setMaxCapital: null,
  basicFrom: 0,
  setBasicFrom: null,
  premiumFrom: 0,
  setPremiumFrom: null,
  leadId: "",
  setLeadId: null,
  locale: "",
  setLocale: null,
});

export const JourneyContextComponent = ({ children }: any) => {
  const [locale, setLocale] = useState("");
  const [name, setName] = useState("");
  const [sex, setSex] = useState("");
  const [postcode, setPostcode] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [insuranceCover, setInsuranceCover] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [minCapital, setMinCapital] = useState(0);
  const [maxCapital, setMaxCapital] = useState(0);
  const [basicFrom, setBasicFrom] = useState(0);
  const [premiumFrom, setPremiumFrom] = useState(0);
  const [leadId, setLeadId] = useState("");
  return (
    <JourneyContext.Provider
      value={{
        locale,
        setLocale,
        name,
        setName,
        sex,
        setSex,
        postcode,
        setPostcode,
        dateOfBirth,
        setDateOfBirth,
        insuranceCover,
        setInsuranceCover,
        phoneNumber,
        setPhoneNumber,
        email,
        setEmail,
        minCapital,
        setMinCapital,
        maxCapital,
        setMaxCapital,
        basicFrom,
        setBasicFrom,
        premiumFrom,
        setPremiumFrom,
        leadId,
        setLeadId,
      }}
    >
      {children}
    </JourneyContext.Provider>
  );
};
